<template>
   <div>
       <el-card>
           <template #header>
                <div class="clearfix" style="text-align:left">
                    <span>申请买断列表</span>
                </div>
            </template>
            <div class="content">
                <div class="content-header">
                    <el-button type="primary" @click="SelectMerchant" style="margin-right:10px;" size="small" v-if="ismerchant != 1">选择商户</el-button>
                    <el-input v-model="keyword"  placeholder="请输入订单号" prefix-icon="el-icon-search" class="hidden-sm-and-down" style="width:250px" size="small"></el-input>
                    <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down" size="small" >搜索</el-button>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="buyoutdata" border style="width: 100%; margin: 1em 0" v-loading="load" 
                                element-loading-spinner="el-icon-loading" 
                                element-loading-text="加载中..."
                                size="small">
                    <el-table-column label="订单号" prop="OrderUID"></el-table-column>
                    <el-table-column label="所属商户" prop="Merchant" min-width="200px">
                        <template #default="scope">
                            <span v-if="scope.row.Merchant">{{scope.row.Merchant}}</span>
                            <span v-else>深圳市闲牛科技有限公司</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单状态" >
                        <template #default="scope">
                            <span v-if="scope.row.OrderStatus == 5">待归还</span>
                            <span v-if="scope.row.OrderStatus == 6">归还中</span>
                            <span v-if="scope.row.OrderStatus == 8">定损中</span>
                            <span v-if="scope.row.OrderStatus == 14">定损待赔付</span>
                            <span v-if="scope.row.OrderStatus == 9">已完成</span>
                            <span v-if="scope.row.OrderStatus == 21">已买断</span>
                            <span v-if="scope.row.OrderStatus == 25">买断中</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品签约价值"  prop="BuyoutPrice">
                        <template #default="scope">
                            <span>{{(scope.row.BuyoutPrice)*1}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="订单总租金"  prop="TotalOrderMoney">
                        <template #default="scope">
                            <span>{{scope.row.TotalOrderMoney*1}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="已付租金"  prop="TotalPayRent">
                        <template #default="scope">
                            <span>{{scope.row.TotalPayRent*1}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="状态">
                        <template #default="scope">
                            <span v-if="scope.row.Status == 0">待处理</span>
                            <span v-if="scope.row.Status == 1">已处理</span>
                            <span v-if="scope.row.Status == 2">已过期</span>
                            <span v-if="scope.row.Status == 3">已拒绝</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请时间" :formatter="Getdate" prop="AddDTime" min-width="100px"></el-table-column>
                    <el-table-column label="操作" min-width="100px" align="right">
                        <template #default="scope">
                            <div v-if="scope.row.Status == 0">
                                <el-button type="text" @click="SetPrice(scope.$index)" size="small">设置买断价格</el-button>
                                <el-button type="text" @click="Reject(scope.row.ID)" size="small">拒绝买断</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange"
                                @size-change="handleSizeChange"
                                :current-page="curpage"
                                :page-size="pagesize"
                                :page-sizes="[10,20,30,50,100]"
                                layout="sizes,total, prev, pager, next, jumper"
                                :total="totalcount"
                                :hide-on-single-page="true"
                                style="text-align:center"></el-pagination>
            </div>
       </el-card>
    </div>
    <el-dialog v-model="editprice" title="设置买断价" width="20%">
        <div class="priceblock">
            <div class="blockitem">套餐签约价值：{{buyoutprice}}</div>
            <div class="blockitem">全部租金：{{totalordermoney}}</div>
            <div class="blockitem">已还租金：{{payrent}}</div>
            <span class="blockitem">套餐买断价：<el-input-number v-model="price" style="width:150px;" :controls="false"></el-input-number></span>
        </div>
        <el-divider></el-divider>
        <div class="bottom">
            <el-button size="small" type="success" @click="Save">保存</el-button>
            <el-button size="small" @click="CloseDialog">取消</el-button>
        </div>
    </el-dialog>
    <el-dialog v-model="reject" title="填写拒绝原因" width="30%">
        <el-input v-model="reason"></el-input>
        <div class="bottom" style="margin-top:15px">
            <el-button size="small" type="primary" @click="SaveReject">保存</el-button>
            <el-button size="small" @click="CloseReject">取消</el-button>
        </div>
    </el-dialog>
    <el-dialog v-model="merchant" title="选择商户" center>
        <div class="merchant-block">
            <el-transfer v-model="selmerchant"
                filterable
                filter-placeholder="请输入商户名称"
                :data='merchantlist'
                :titles='transfertitle'>
            </el-transfer>
            <div class="bottom">
                <el-button type="primary" size="small" @click="ConfirmMerchant">确定</el-button>
                <el-button size="small" @click="Back">取消</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<style scoped>
.merchant-block {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merchant-block .bottom {
    margin-top: 20px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.priceblock{
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}
.blockitem{
    padding:15px;
}
.bottom{

    line-height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /*border-top: #dcdfe6 1px solid;*/
}
</style>
<script>
import Cookies from 'js-cookie'
import constant from "@/constant"
export default {
    data(){
        return {
            keyword:'',
            selmerchant:[],
            transfertitle:['商户列表','已选商户'],
            merchantlist:[],
            merchant:false,
            reason:'',
            reject:false,
            buyoutdata:[],
            editprice:false,
            curid:'',
            orderid:'',
            price:0.00,
            deposit:'0.00',
            decrease:'0.00',
            replenish:'0.00',
            paymoney:'0.00',
            depositbreak:'0.00',
            discount:'0.00',
            curpage:1,
            pagesize:10,
            totalcount:"",
        }
    },
    methods:{
        ConfirmMerchant(){
            console.log(this.selmerchant);
            this.merchant = false;
        },
        Back(){
            this.merchant = false;
        },
        SelectMerchant(){
            this.merchant = true;
        },
        SaveReject(){
            this.axios.get(constant.savereject,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id: this.curid,
                    reason: this.reason,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK")
                    this.$router.go(0);
            })
        },
        CloseReject(){
            this.curid = '';
            this.reject = false;
        },
        Save(){
            this.axios.get(constant.save_buyout,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id: this.curid,
                    price: this.price,
                }
            }).then((response)=>{
                console.log(response.data);
                if(response.data == "OK"){
                    this.$message.success("操作成功");
                    this.$router.go(0);
                }else
                    this.$message.error("操作成功");
            })
        },
        CloseDialog(){
            this.editprice = false;
            this.curid = "";
        },
        SetPrice(index){
            /*
            console.log(id);
            this.curid = id;
            this.editprice = true;
            this.axios.get(constant.get_order,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id: this.curid,
                }
            }).then((response)=>{
                console.log(response.data);
                this.orderid = response.data.ID;
                this.deposit = response.data.Deposit;
                this.decrease = (response.data.SDecrease*1 + response.data.RDecrease*1);
                this.replenish = response.data.Replenish;
                this.paymoney = response.data.TotalPayMoney;
                this.break = response.data.TotalBreakMoney;
                this.discount = response.data.TotalDiscountMoney;
            });*/
            console.log(index);
            this.curid = this.buyoutdata[index].ID;
            this.buyoutprice = this.buyoutdata[index].BuyoutPrice;
            this.totalordermoney = this.buyoutdata[index].TotalOrderMoney;
            this.payrent = this.buyoutdata[index].TotalPayRent;
            this.editprice = true;
        },
        handleCurrentChange(val){
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val){
            console.log(val);
            this.pagesize = val;
            this.init();
        },
        SaveSet(){
            this.axios.get(constant.set_buyoutprice,{
                headers:{
                    'Content-Type': 'application/json'
                },
                params:{
                    id: this.curid,
                    orderid: this.orderid,
                    price: this.price,
                }
            }).then((response)=>{
                console.log(response.data);
            });
        },
        Getdate(row, column){
            var cur_date = row[column.property];
            console.log(cur_date);
            return  formatDate(cur_date);
            function formatDate(date){
                var datetime = new Date(date*1000);
                var year = datetime.getFullYear();
                var month = ("0" + (datetime.getMonth() + 1)).slice(-2);
                var day = ("0" + datetime.getDate()).slice(-2);
                var hour = ("0" + datetime.getHours()).slice(-2);
                var minute = ("0" + datetime.getMinutes()).slice(-2); 
                var second = ("0" + datetime.getSeconds()).slice(-2); 
                return year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second;
            }
        },
        Reject(id){
            this.curid = id;
            this.reject = true;
        },
        init(){
            this.axios.get(constant.get_apply_list,{
                headers:{
                        'Content-Type': 'application/json'
                },
                params:{
                    ismerchant:this.ismerchant,
                    mid:this.mid,
                    keyword:this.keyword,
                }
            }).then((response)=>{
                console.log(response.data);
                this.buyoutdata = response.data.list;
                this.curpage = response.data.curpage*1;
                this.totalcount = response.data.totalcount*1;
            });
        }
    },
    created:function(){
        this.ismerchant = Cookies.get("IsMerchant");
        this.mid = Cookies.get("mid");
        this.axios.get(constant.get_apply_list,{
            headers:{
                    'Content-Type': 'application/json'
            },
            params:{
                ismerchant:this.ismerchant,
                mid:this.mid,
            }
        }).then((response)=>{
            console.log(response.data);
            this.buyoutdata = response.data.list;
            this.curpage = response.data.curpage*1;
            this.totalcount = response.data.totalcount*1;
        });

        this.axios.get(constant.allmerchant,{
            headers:{
                "Content-Type":'application/json'
            }
        }).then((response)=>{
            console.log("商户列表");
            console.log(response.data);
            let list = response.data;
            this.merchantlist = [];
            for(let i = 0;i<list.length;i++){
                let obj = new Object();
                obj.label = list[i]['Merchant'];
                obj.key = list[i]['ID'];
                this.merchantlist.push(obj);
            }
            console.log(this.merchantlist);
        });
    }
}
</script>